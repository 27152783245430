<template>
    <div class="p-2 card">
        <b-row>
            <b-col
                md="12"
                lg="6">
                <h1>Gerenciar tópicos de ajuda</h1>
                <p>
                    Para realizar uma busca, selecione o(s) filtros necessário(s) e clique no botão buscar:
                </p>
            </b-col>

            <b-col
                md="12"
                lg="6"
                class="text-right"
            >
                <b-button
                    v-if="$can(
                      regraAcao.inserir,
                      regraEntidade.portal.ajudaESuporte.topico
                    )"
                    @click="atualizarRegistros = false; mostrarModal = true;"
                    type="button"
                    variant="outline-primary"
                    class="float-right"
                >
                    <BIconPlusCircle />
                    &nbsp;
                    Incluir Novo Tópico de Ajuda
                </b-button>
                <b-button
                    v-else
                    disabled="disabled"
                    type="button"
                    variant="outline-primary"
                    class="float-right"
                >
                    <BIconPlusCircle />
                    &nbsp;
                    Você não tem permissão para incluir
                </b-button>
            </b-col>
        </b-row>

        <section class="filtros mt-2">
            <b-form>
                <b-row>
                    <b-col xl="12" md="12">
                        <b-form-group label="Termo de pesquisa" label-for="pesquisa">
                            <b-form-input
                                id="pesquisa"
                                v-model="busca.pesquisa"
                                autocomplete="off"
                                @keydown.prevent.enter="buscarDadosTopicosAjuda"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="w-100 d-flex justify-content-center my-2">
                    <b-button
                        class="mr-2"
                        type="reset"
                        variant="outline-secondary"
                        @click="limparFiltros"
                    >
                        Limpar
                    </b-button>

                    <b-button
                        :disabled="desabilitarBotao"
                        @click.prevent="buscarDadosTopicosAjuda"
                        ref="filtrosAjuda"
                        type="button"
                        variant="custom"
                    >
                        Buscar
                    </b-button>
                </div>
            </b-form>

            <b-modal
                id="modal-ajuda"
                ref="modal-ajuda"
                :title="tituloModal"
                size="lg"
                centered
                v-model="mostrarModal"
                @close="esconderModal"
                :hide-footer="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
            >
                <FormTopico :form="form"
                            :categorias="categoriasSelect"
                            :placeholderCategorias="placeholderCategorias"
                            :update-action="atualizarRegistros"
                            @success="sucessoInserirAtualizar"
                            @cancel="esconderModal"
                            @fechaModalEAtualiza="fechaModalEAtualiza"
                />
            </b-modal>

            <b-modal
                id="modal-detalhes-ajuda"
                ref="modall-detalhes-ajuda"
                title="Detalhes do tópico de ajuda"
                size="lg"
                centered
                v-model="mostrarModalDetalhes"
                @close="esconderModalDetalhes"
                :hide-footer="true"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
            >
                <DetalheTopico :form="form"
                            :categorias="categoriasSelect"
                            :placeholderCategorias="placeholderCategorias"
                            @cancel="esconderModalDetalhes"
                />
            </b-modal>
        </section>

        <div v-if="carregandoTabela" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
            <span>carregando...</span>
        </div>

        <b-alert variant="primary" :show="tabela.semDados" class="mt-5">
            <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                    <strong class="text-primary">Nenhum registro encontrado.</strong>
                    Realize novamente uma busca utilizando diferentes critérios.
                </span>
            </div>
        </b-alert>

        <b-alert variant="primary" :show="tabela.erroTabela" class="mt-5">
            <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                    <strong class="text-primary">Sistema de busca indisponível no momento.</strong>
                </span>
            </div>
        </b-alert>

        <section v-if="mostrarTabela">
            <b-row class="my-2">
                <b-col sm="2">
                    <div class="d-flex flex-row align-items-center">
                        <label class="mr-50">Visualizar</label>
                        <b-form-select
                            v-model="dadosPaginacao.quantidadeLinhasPadrao"
                            class="w-50"
                            size="sm"
                            id="amounlinesVisualizer"
                            label="option"
                            @input="atualizaQuantidadeVisualizar($event)"
                            :options="tabela.quantidadeLinhasVisualizar"
                        />
                    </div>
                </b-col>
                <b-col sm="12" class="my-2">
                    <b-table
                        id="listCompaniesTable"
                        class="font-small-3"
                        responsive
                        @context-changed="ordenarTabela"
                        :busy.sync="tabela.tabelaOcupada"
                        :no-local-sorting="true"
                        :fields="tabela.fields"
                        :items="tabela.items"
                    >
                        <template #cell(topico)="row">
                          {{ $helpers.truncateText(row.item.topico, 65) }}
                        </template>
                        <template #cell(categorias)="row">
                            <span v-for="(categoria, index) in row.value" :key="index">
                                {{ $helpers.truncateText(categoria.titulo, 65) }}{{ index !== (row.value.length - 1) ? ', ' : '' }}
                                <br />
                            </span>
                        </template>
                        <template #cell(status)="row">
                          <b-form-checkbox
                            :ref="`check-status-${row.item.id_categoria}`"
                            v-model="row.item.ativo"
                            name="check-button"
                            class="custom-control-success"
                            switch
                            inline
                            @change="showStatusModal($event, row.item)"
                          />
                        </template>
                        <template #cell(acoes)="row">
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <b-button
                              v-if="$can(
                                regraAcao.atualizar,
                                regraEntidade.portal.ajudaESuporte.topico
                              )"
                              @click="retornaAjudaId(row.item)"
                              variant="outline-primary"
                              class="btn-icon"
                            >
                                <feather-icon icon="EditIcon" />
                            </b-button>
                            <b-button
                              v-else
                              @click="retornaAjudaIdDetalhes(row.item)"
                              variant="outline-primary"
                              class="btn-icon"
                            >
                                <feather-icon icon="EyeIcon" />
                            </b-button>
                            <b-button
                                v-if="$can(
                                  regraAcao.remover,
                                  regraEntidade.portal.ajudaESuporte.topico
                                )"
                                @click="excluirTopicoId(row.item)"
                                variant="outline-primary"
                                class="ml-1 btn-icon"
                              >
                                  <feather-icon icon="Trash2Icon" />
                              </b-button>
                          </div>
                        </template>
                    </b-table>
                </b-col>
                <b-col sm="12">
                    <CustomPagination :paginacao="dadosPaginacao" @page-cliked="atualizarPaginaAtual"/>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
import {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BSpinner,
    BAlert,
    BIconPlusCircle,
    BFormCheckbox
} from 'bootstrap-vue';
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import FormTopico from "@/views/pages/ajuda-suporte/FormTopico";
import DetalheTopico from "@/views/pages/ajuda-suporte/DetalheTopico";
import helpers from "@/helpers";
import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js';
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
    components: {
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BIconPlusCircle,
        BFormSelect,
        BButton,
        BFormCheckbox,
        BTable,
        BTableSimple,
        BTbody,
        BTr,
        BTd,
        BAlert,
        BSpinner,
        ValidationProvider,
        ValidationObserver,
        FormTopico,
        DetalheTopico,
        Cleave,
        CustomPagination,
        vSelect
    },

    mounted() {
        this.dadosSelect();
    },

    data() {
        return {
            desabilitarBotao: false,
            busca: {
                pesquisa: '',
            },

            form: {
                id_ajuda: null,
                topico: null,
                conteudo: null,
                categorias: [],
            },

            atualizarRegistros: false,

            ajudas: [],

            categoriasSelect: [],
            placeholderCategorias: 'Aguarde, buscando registros...',

            mostrarModalDetalhes: false,
            mostrarModal: false,
            tituloModal: 'Incluir novo tópico de ajuda',

            mostrarTabela: false,
            carregandoTabela: false,

            dadosPaginacao: {
                paginaAtual: 1,
                totalLinhas: 1,
                daLinha: 1,
                ateALinha: 1,
                quantidadeLinhasPadrao: 10,
            },

            tabela: {
                semDados: false,
                erroTabela: false,
                tabelaOcupada: false,
                quantidadeLinhasVisualizar: [10, 25, 50, 100],
                ordem: '',
                campoOrdenado: '',
                fields: [
                    {key: "topico", label: "Tópico de ajuda", sortable: true, class: 'max-width-topico-table font-small-3'},
                    {key: "atualizado", label: "Atualizado", sortable: true, class: 'max-width-atualizado-table font-small-3'},
                    {key: "categorias", label: "Categoria", sortable: false, class: 'max-width-categoria-table font-small-3'},
                    {key: 'status', label: 'status', class: 'text-center font-small-3' },
                    {key: "acoes", label: "Ações", class: 'mw-50 text-center font-small-3'}
                ],
                items: []
            },

            regraAcao: $regraAcao,
            regraEntidade: $regraEntidade
        }
    },

    methods: {

        async dadosSelect() {
            await this.retornaCategorias();
        },

        buscarDadosTopicosAjuda(firstTime = null) {
            if(firstTime && !this.mostrarTabela) {
                this.carregandoTabela = true;
                this.desabilitarBotao = true;
            }

            this.tabela.erroTabela = false;

            this.tabela.semDados = false;
            this.tabela.tabelaOcupada = true;

            this.$http.get(this.$api.AjudaSuporte, {
                params: this.lidarComParametrosBuscaAjuda()
            }).then((response) => {

                this.desabilitarBotao = false;

                if(response.status === 200) {
                    if(response.data.data.length > 0) {
                        this.mostrarTabela = true;
                        this.tabela.items = response.data.data;
                        this.tabela.tabelaOcupada = false;
                        this.carregandoTabela = false;
                        this.IniciarPaginacao(response.data);
                        return;
                    }
                    this.carregandoTabela = false;
                    this.tabela.semDados = true;
                    this.mostrarTabela = false;
                }
            }).catch(() => {
                this.desabilitarBotao = false;
                this.carregandoTabela = false;
                this.tabela.erroTabela = true;
            });
        },

        sucessoInserirAtualizar() {
            this.esconderModal();

            this.buscarDadosTopicosAjuda();
        },

        async retornaAjudaId(ajuda) {
            this.form.id_ajuda = ajuda.id_ajuda;

            await this.$http.get(`${this.$api.AjudaSuporte}/${this.form.id_ajuda}`)
                .then((response) => {
                    const ajudaResponse = response.data;

                    this.form.topico = ajudaResponse.topico;
                    this.form.conteudo = ajudaResponse.conteudo;
                    this.form.categorias = ( ajudaResponse.categorias!=null ? helpers.retornaAtributoArray(ajudaResponse.categorias, 'id_categoria'):null);

                    this.tituloModal = 'Editar Tópico de Ajuda';
                    this.atualizarRegistros = true;
                    this.mostrarModal = true;
                });
        },

        async retornaAjudaIdDetalhes(ajuda) {
            this.form.id_ajuda = ajuda.id_ajuda;

            await this.$http.get(`${this.$api.AjudaSuporte}/${this.form.id_ajuda}`)
                .then((response) => {
                    const ajudaResponse = response.data;

                    this.form.topico = ajudaResponse.topico;
                    this.form.conteudo = ajudaResponse.conteudo;
                    this.form.categorias = ajudaResponse.categorias;

                    this.atualizarRegistros = true;
                    this.mostrarModalDetalhes = true;
                });
        },

        ordenarTabela(contextoTabela) {
            this.tabela.campoOrdenado = contextoTabela.sortBy;
            this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

            this.buscarDadosTopicosAjuda();
        },

        lidarComParametrosBuscaAjuda() {
            return {
                "pesquisa": this.busca.pesquisa,
                "colunaNome": this.tabela.campoOrdenado,
                "colunaOrdem": this.tabela.ordem,
                "perPage": this.dadosPaginacao.quantidadeLinhasPadrao,
                "page": this.dadosPaginacao.paginaAtual
            }
        },

        limparFiltros() {
            this.busca.pesquisa = '';

            this.mostrarTabela = false;

            this.limparPaginacao();
        },

        limparCampos() {
            this.form = {
                categorias: [],
                topico: '',
                conteudo: ''
            };

            this.tituloModal = 'Incluir novo tópico de ajuda';
        },

        limparPaginacao() {
            this.dadosPaginacao = {
                paginaAtual: 1,
                totalLinhas: 1,
                daLinha: 1,
                ateALinha: 1,
                quantidadeLinhasPadrao: 10,
            };
        },

        IniciarPaginacao(dadosPaginacao) {
            this.dadosPaginacao.daLinha = dadosPaginacao.from;
            this.dadosPaginacao.ateALinha = dadosPaginacao.to;
            this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
            this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
            this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
        },

        atualizarPaginaAtual(page) {
            this.dadosPaginacao.paginaAtual = page;
            this.buscarDadosTopicosAjuda()
        },

        atualizaQuantidadeVisualizar(event) {
            if(!event) {
                this.dadosPaginacao.quantidadeLinhasPadrao = 10;
            }

            this.dadosPaginacao.paginaAtual = 1;
            this.buscarDadosTopicosAjuda();
        },

        esconderModal() {
            this.limparCampos();
            this.mostrarModal = !this.mostrarModal;
        },

        esconderModalDetalhes() {
            this.limparCampos();
            this.mostrarModalDetalhes = !this.mostrarModalDetalhes;
        },

        retornaCategorias() {
            this.$http.get(this.$api.AjudaCategoria).then((res) => {
                this.categoriasSelect = res.data.data;
                this.placeholderCategorias = 'Selecione uma ou mais categorias';
            });
        },

        showStatusModal(action, topico){
          modalGenericModel(
            action ? 'Tem certeza que deseja ativar?'
            : 'Tem certeza que deseja inativar?',
            action ?
            'Este tópico voltará a ser exibida na tela de FAQ' :
            'Este tópico não será mais exibida na tela de FAQ',
            require('@/assets/custom-icons/cora-icons/alert.png'),
            action ?
            'Sim, ativar' :
            'Sim, inativar',
            'Cancelar'
          ).then((result) => {
            if(result.isConfirmed){
              this.changeState(topico);
            }else{
              topico.ativo = !topico.ativo
            }
          })
        },

        changeState(topico){
          this.$http.patch(
            `${this.$api.AjudaSuporte}/${topico.id_ajuda}`
          )
          .then()
          .catch(() => {
            topico.ativo = !topico.ativo
            this.showGenericErrorModal()
          });
        },

        showGenericErrorModal(){
          modalGenericModel(
            'Ocorreu um erro!',
            'Por favor tente novamente mais tarde ou contate nosso suporte.',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
        },

        async excluirTopicoId(topico){
          this.setBackgroundModalInert(true)
          await modalGenericModel(
            'Tem certeza que deseja excluir?',
            '',
            require('@/assets/custom-icons/cora-icons/alert.png'),
            'Sim, excluir',
            'Não, manter'
          ).then((result) => {
            if(result.isConfirmed){
              this.$http.delete(
                `${this.$api.AjudaSuporte}/${topico.id_ajuda}`
              )
              .then(() => {
                this.buscarDadosTopicosAjuda()
              })
              .catch(() => {
                this.showGenericErrorModal()
              });
            }
          })
          this.setBackgroundModalInert(false)
        },

        setBackgroundModalInert(status){
          console.log("teste")
          const modalIds = ['modal-ajuda', 'modal-detalhes-ajuda'];

          modalIds.forEach((id) => {
            const foundModal = document.getElementById(id);
            if (foundModal) {
              foundModal.inert = status;
            }
          });
        },

        fechaModalEAtualiza(){
          this.mostrarModal = false;
          this.limparCampos();
          this.buscarDadosTopicosAjuda()
        }
    }
}
</script>
<style lang="scss">
.max-width-topico-table{
  max-width: 280px;
}
.max-width-atualizado-table{
  max-width: 150px;
}
.max-width-categoria-table{
  max-width: 280px;
}
</style>
