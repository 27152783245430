<template>
    <div>
        <div v-if="loading" class="w-100 mt-5 mb-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
            <span>Processando...</span>
        </div>
        <b-form v-if="!loading">
            <b-row>
                <b-col
                  cols="3"
                  offset="9"
                  class="text-right"
                >
                  <b-button
                    v-if="$can(
                      regraAcao.remover,
                      regraEntidade.portal.ajudaESuporte.categoria
                    ) && form.id_ajuda"
                    @click="excluirTopicoId()"
                    variant="outline-primary"
                    class="px-1 ml-1 btn-icon"
                  >
                      <feather-icon icon="Trash2Icon" />
                      Apagar
                  </b-button>
                </b-col>
                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Tópico *" label-for="topico">
                        <b-form-input
                            id="nome"
                            v-model="getForm.topico"
                            autocomplete="off"
                            placeholder="Ex: Como fazer uma determinada ação?"
                        />
                        <small class="text-danger">{{ errors.topico }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Conteúdo *" label-for="conteudo">
                      <editor-content
                        id="conteudo"
                        ref="tiptapConteudo"
                        :dadosContent="form.conteudo"
                        placeholder="Ex: Você faz uma determinada ação clicando aqui e depois ali."
                      />
                      <small class="text-danger">{{ errors.conteudo }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Categorias *" label-for="categorias" class="m-0">
                        <v-select
                            id="categorias"
                            v-model="getForm.categorias"
                            :reduce="(option => option.id_categoria)"
                            :options="categorias"
                            variant="custom"
                            item-text="titulo"
                            item-value="id_categoria"
                            label="titulo"
                            :placeholder="placeholderCategorias"
                            multiple
                            multiselect
                        />
                    </b-form-group>

                    <small class="text-danger">{{ errors.categorias }}</small>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="6">
                  <b-button
                      variant="outline-secondary"
                      class="btn float-right"
                      @click="handleCancel"
                    >
                    Cancelar
                  </b-button>
                </b-col>

                <b-col cols="12" sm="6">
                  <b-button
                      variant="custom"
                      class="btn float-left"
                      @click="core"
                    >
                    Salvar
                  </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import FieldCategorias from '@/views/components/custom/ajuda/CategoriasSelect.vue';
import vSelect from "vue-select";
import { validation } from "@core/mixins/validation/validation";
import { messages } from "@core/mixins/validation/messages";
import { modalGenericModel } from '@/libs/sweetalerts'
import EditorContent from '@/views/extensions/tiptap/EditorContent.vue';
import { $regraAcao, $regraEntidade } from "@/custom-enum/regras-enum";

import {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
    BFormTextarea
} from 'bootstrap-vue';

export default {
    components: {
        FieldCategorias,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BSpinner,
        BFormTextarea,
        vSelect,
        EditorContent
    },

    placeholderCategorias: 'Aguarde, buscando registros...',

    props: [
        'form',
        'categorias',
        'placeholderCategorias',
        'updateAction',
    ],

    mixins: [validation, messages],

    mounted() {
      this.setCurrentData();
    },

    data() {
        return {
            errors: {},
            currentCategorias: [],
            loading: false,
            regraAcao: $regraAcao,
            regraEntidade: $regraEntidade
        }
    },

    methods: {
        async handleInsert() {
            if(this.handleValidation()) {
                this.loading = true;

                const formData = this.handleFormData(this.getForm);

                await this.$http.post(this.$api.AjudaSuporte, formData)
                    .then((_response) => {
                        this.handleClear();
                        this.handleModalSuccess();
                    })
                    .catch((_error) => {
                        this.handleApiError(_error.response);
                    })

                this.loading = false;
            }
        },

        async handleUpdate() {
            if(this.handleValidation()) {
                this.loading = true;

                const formData = this.handleFormData(this.getForm);

                await this.$http.put(`${this.$api.AjudaSuporte}/${this.getForm.id_ajuda}`, formData)
                    .then((_response) => {
                        this.handleClear();
                        this.handleModalSuccess();
                    })
                    .catch((_error) => {
                        this.handleApiError(_error.response);
                    })

                this.loading = false;
            }
        },

        handleValidation() {
            this.strValidate({
                input: this.getForm.topico,
                message: this.requiredFieldMsg,
                field: 'topico'
            });

            this.strValidate({
              input: this.$helpers.removeHTMLTags(
                  this.$refs.tiptapConteudo.editor.getHTML()
                ),
                message: this.requiredFieldMsg,
                field: 'conteudo'
            });

            this.arrayEmptyValidate('categorias', this.getForm.categorias);

            if(! this.formValidationRun()) {
                this.errors = {};
                this.errors = this.formValidationErrors;
                return false;
            }

            return true;
        },

        handleApiError(_response) {
          if(_response){
            const status = _response.status
            const error = _response.data.error ?? _response.data.errors

            this.errors = {};

            if(status && (status === 400 || status === 422)) {
              return this.handleError(error)
            }
          }
          modalGenericModel(
            'Não foi possível realizar sua requisição, entre em contato com o suporte',
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Ok'
          )
        },

        handleError(error){
          if(error.field) {
            switch (error.field) {
              case 'topico':
                this.errors.topico = error.message;
                break;
              case 'conteudo':
                this.errors.conteudo = error.message;
                break;
              case 'categorias':
                this.errors.categorias = error.message;
                break;
            }
          }else{
            this.errors.topico = error.topico ? error.topico[0] : null;
            this.errors.conteudo = error.conteudo ? error.conteudo[0] : null;
            this.errors.categorias = error.categorias ? error.categorias[0] : null;
          }
        },

        handleFormData(formData) {
            return {
                topico: formData.topico,
                conteudo: this.$refs.tiptapConteudo.editor.getHTML(),
                categorias: formData.categorias,
            };
        },

        handleModalSuccess() {
          modalGenericModel(
            'Sucesso!',
            'Dados salvos com sucesso.',
            require('@/assets/custom-icons/cora-icons/success.png'),
            'Ok'
          ).then((_result) => {
            if (_result.isConfirmed) {
                this.$emit('success');
            }
          });
        },

        handleClear() {
            this.errors = {};
        },

        handleCancel() {
            this.handleClear();
            this.$emit('cancel');
        },

        core() {
            this.updateAction ? this.handleUpdate() : this.handleInsert();
        },

        setCurrentData() {
            this.currentCategorias = this.getForm.categorias;
        },

        showGenericErrorModal(){
          modalGenericModel(
            'Ocorreu um erro!',
            'Por favor tente novamente mais tarde ou contate nosso suporte.',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
        },

        excluirTopicoId(){
          modalGenericModel(
            'Tem certeza que deseja excluir?',
            '',
            require('@/assets/custom-icons/cora-icons/alert.png'),
            'Sim, excluir',
            'Não, manter'
          ).then((result) => {
            if(result.isConfirmed){
              this.$http.delete(
                `${this.$api.AjudaSuporte}/${this.getForm.id_ajuda}`
              )
              .then(() => {
                this.$emit('fechaModalEAtualiza');
              })
              .catch(() => {
                this.showGenericErrorModal()
              });
            }
          })
        }
    },

    computed: {
        getForm() {
            return this.form;
        }
    }
}
</script>

<style scoped>
.btn-insert {
    background: linear-gradient(45deg, #7030A0 0%, #A369CE 100%);
    color: #fff;
    transition: .3s background-color;
}

.btn-insert:hover {
    background: linear-gradient(45deg, #60248d 0%, #9950d0 100%);
    color: #fff;
}

.btn-outline-cancel {
    background-color: transparent;
    border: solid 0.094rem #82868B;
    color: #82868B;
    transition: .3s;
}

.btn-outline-cancel:hover {
    text-decoration: underline;
}

@media(max-width: 576px) {
    .btn-insert, .btn-outline-cancel  {
        width: 100%;
        margin-bottom: 15px;
    }
}
</style>
